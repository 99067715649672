
.close-icon {
    transition: 0.3s;
    border-radius: 50px !important;
    margin-top: 20px;
    margin-right: 15px;
    padding: 3px;

    &:hover {
        background-color: #2c423d;
        color: white;
    }
}

.section-header {
    color: #389877;
}

.item-column {
    gap: 20px;
}

.members-container {
    max-height: 300px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 0 10px 0;
}

.inner-member {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-btn {
    width: 25px !important;
    height: 25px !important;
}

.action-row {
    margin-top: 5px;
    margin-bottom: -50px;
}
